import { render, staticRenderFns } from "./resultokre.vue?vue&type=template&id=5ac6c8b7&scoped=true&"
import script from "./resultokre.vue?vue&type=script&lang=js&"
export * from "./resultokre.vue?vue&type=script&lang=js&"
import style0 from "./resultokre.vue?vue&type=style&index=0&id=5ac6c8b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac6c8b7",
  null
  
)

export default component.exports