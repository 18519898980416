<!-- 付款结果 充值-->
<template>
  <div class="resultokre">
    <div class="title">
      <div class="lt">
        <img src="../../assets/img/payment/payok.png">
      </div>
      <div class="rt">
        <p>恭喜您，支付成功！<span><svg><use xlink:href="#iconwancheng"></use></svg></span></p>
        <p>预存余额已到达您的账户.</p>
      </div>
    </div>
    <div class="group-list">
      <div class="lt">充值金额</div>
      <div class="rt price">{{infos?infos.PayAmount:'' | formatPrice}}元</div>
    </div>
    <div class="group-list">
      <div class="lt">充值账户</div>
      <div class="rt">{{infos?infos.customerName:''}}</div>
    </div>
    <div class="group-list">
      <div class="lt">订单编号</div>
      <div class="rt">{{infos?infos.orderName:''}}</div>
    </div>
    <div class="group-list">
      <div class="lt">充值方式</div>
      <div class="rt">{{infos?infos.type:''}}</div>
    </div>
    <div class="group-list">
      <div class="lt">充值时间</div>
      <div class="rt">{{infos&&infos.payDate?infos.payDate.split('T')[0]:''}}</div>
    </div>
    <div class="menus">
      <button @click='seeOrder'>查看余额</button>
      <button @click='backShoping'>返回主页</button>
    </div>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'resultokre',
  mixins: [mixin],//混入
  data(){
    return {
      // datas:{
      //   data:12321312,
      //   data1:'支付宝',
      //   data2:'2019-10-19',
      //   data3:418,

      // }
    }
  },
  components: {
  },
  props: {
    infos: null
  },
  methods:{
    seeOrder(){
      // 查看余额
      this._router({then:()=>{
        this.$router.push({path:'/userprice.html'})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/userprice.html'}})
      }})
    },
    // formatPrice
    backShoping(){
      // 返回主页
      this.$router.push({path:'/'})
    }
  }
}
</script>
<style  lang="scss" scoped>
.resultokre {
  .title {
    display: flex;justify-content: center;height: 230px;align-items: center;
    .lt {
      img {
        display: block;width: 220px;
      }
    }
    .rt {
      margin-left:30px;
      p {
        font-size: 20px;font-weight: bold;
        &:nth-child(1) {
          margin-bottom: 10px;
        }
        span {
          display: inline-block;width: 22px;height: 22px;border-radius: 50%;background: #83d3ff;line-height: 22px;text-align: center;vertical-align: 1px;
          svg {
            width: 12px;height: 12px;fill: #fff;margin-top:6px;
          }
        }
      }
    }
  }
  .group-list {
    width: 400px;height: 40px;background: #f9fafe;display: flex;justify-content: space-between;align-items: center;margin:0 auto;padding:0 50px;color:#333;margin-bottom:5px;font-size: 14px;
    .lt {
      color:#555658;
    }
    .rt {
      color:#2b2c30;font-weight: bold;
    }
    .price {
      color:#f44f56;
    }
  }
  .menus {
    display: flex;justify-content: center;margin-top:50px;
    button {
      width: 180px;height: 50px;border:1px solid #bfbfbf;background: #fff;font-size: 20px;border-radius: 5px;color:#404040;
      &:nth-child(1) {
        margin-right: 20px;background: #f44f56;border-color:#f44f56;color:#fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
