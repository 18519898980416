<!--充值 付款结果-->
<template>
  <div class="payresultre">
    <el-row class='head'>
      <el-col :span="12">
        <logotitle title='充值'></logotitle>
      </el-col>
      <el-col :span="12">
        <rechargestep class='rechargestep' :steps='2'></rechargestep>
      </el-col>
    </el-row>
    <resultokre  :infos='initData' class='resultok'></resultokre>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import resultokre from '@/components/resultokre/resultokre.vue';//支付成功-充值
import resultno from '@/components/resultno/resultno.vue';//支付失败
import rechargestep from '@/components/rechargestep/rechargestep.vue';//充值步骤
export default {
  name: 'payresultre',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'支付结果-微微定',
      initData:{}
    }
  },
  components: {
    logotitle,
    resultokre,
    resultno,
    rechargestep
  },
  props: {
    msg: String
  },
  methods:{
    localReset(){
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ExistDepositOrder?orderNumber='+this._spf(this.$route.query.orderNumber),this.keys())
        .then(res => {
            if(res.data.Success){
              if(res.data.Data.state==200){
                this.initData = res.data.Data
              }
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.payresultre {
  width: 100%;background: #fff;
  .head {
    width: 1200px;margin:0 auto;padding-top:20px;padding-bottom:20px;
    .buystep {
      float:right;margin-top:10px;
    }
  }
  .resultok {
    width: 1200px;margin:0 auto;padding-bottom:150px;padding-top:20px;
  }
  .resultno {
    width: 1200px;margin:0 auto;padding-bottom:150px;padding-top:20px;
  }
}
</style>
